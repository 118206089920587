import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;

  &:focus {
    outline: none;
    border-color: #40a9ff;
    box-shadow: 0 0 5px rgba(24, 144, 255, 0.2);
  }

  &::placeholder {
    color: #bfbfbf;
  }
`;

const handleCancellation = (key, cadence, cadenceData, setCadenceData, user) => {
  if (key === 'canceled') {
    Modal.confirm({
      title: 'Informe o motivo',
      content: (
        <div>
          <StyledTextArea
            id="reasonInput"
            placeholder="Informe detalhes"
          />
        </div>
      ),
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: () => {
        const reason = document.getElementById('reasonInput').value;
        const updatedCadenceData = cadenceData.map(c => {
          if (c.uid === cadence.uid) {
            if (!c.comments) {
              c.comments = [];
            }

            c.comments.push({
              date: moment().format('DD/MM/YYYY HH:mm:ss'),
              text: reason,
              user: user.id,
            });

            c.status = 'canceled';
            c.done_at = moment().format('YYYY-MM-DD HH:mm:ss');
            c.done_by = user.id;
          }
          return c;
        });
        setCadenceData(updatedCadenceData);
      },
    });
    return;
  }
};

export default handleCancellation;