import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';
import { leadsTable } from './tableColumns';
import * as leadsTableActions from './actions';
import * as leadDrawer from '../DrawerLeads/actions';

export const LeadsTable = ({
    columns, 
    showDrawer, 
    setSelectedRows, 
    setSelectedRowsKeys,
    period,
}) => {
    const dispatch = useDispatch();
    const {
        isLoading,
        data,
    } = useSelector(state => state.leadsTable);

    const [filterBy, setFilterBy] = useState("created_at");

    useEffect(() => {
        if (!period) return;
        dispatch(leadsTableActions.loadLeads({ ...period }));
    }, [period, filterBy]);

    const handleRevised = (checked, record) => {
        dispatch(leadDrawer.updateReview(record.id, checked));
    }

    const tableColumns = leadsTable.getColumns({ columns, showDrawer, handleRevised });
    const columnsWidth = tableColumns.reduce((acc, column) => acc + column.width, 0);

    return (
        <div>
            <div className={`table-clabs`}>
                <Table
                    loading={isLoading}
                    tableLayout="fixed"
                    pagination={getDefaultPaginationConfig({
                        storageKey: STORAGE.defaultPageSize.customers
                    })}
                    bordered={true}
                    rowClassName={(record) => record.is_revised ? 'row-clabs row-green' : 'row-clabs'}
                    columns={tableColumns}
                    dataSource={data}
                    rowKey={(record) => record.id}
                    scroll={{ x: columnsWidth, y: `${window.innerHeight - 175}px` }}
                    rowSelection={{
                        type: 'checkbox',
                        fixed: true,
                        onChange: (selectedRowKeys, selectedRows) => {
                            setSelectedRows(selectedRows);
                            setSelectedRowsKeys(selectedRowKeys);
                        }
                    }}
                />
            </div>
        </div>
    );
}