import React from 'react';
import { get } from 'lodash';

import Calendar from './Calendar';
import { Container } from './styles';

export function LeadCalendarIcon({ item: lead }) {
  const firstPending = get(lead, 'additional_data.nextPendingTask', null);

  if (!firstPending) return null;

  return (
    <Container>
      <Calendar data={firstPending} />
    </Container>
  );
}
