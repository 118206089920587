import React, { useEffect } from 'react';
import { Modal, Form, Input, message } from 'antd';

const Edit = ({ visible, onClose, onSave, initialValue, form, existingNames }) => {
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = form;

  useEffect(() => {
    if (visible) {
      setFieldsValue({ value: initialValue });
    }
  }, [visible, initialValue, setFieldsValue]);

  const handleSave = () => {
    validateFields((errors, values) => {
      if (!errors) {
        const newValue = values.value;
        if (existingNames.includes(newValue) && newValue !== initialValue) {
          Modal.confirm({
            title: "Já existe uma opção com este nome. Deseja mesclar?",
            content: "Esta ação não poderá ser revertida.",
            okText: "Sim",
            okType: "danger",
            cancelText: "Cancelar",
            onOk: () => {
              onSave(newValue, true);
              resetFields();
            },
          });
        } else {
          onSave(newValue, false);
          resetFields();
        }
      }
    });
  };

  return (
    <Modal
      title="Editar Nome"
      visible={visible}
      onCancel={onClose}
      onOk={handleSave}
    >
      <Form layout="vertical">
        <Form.Item
          label="Nome"
        >
          {getFieldDecorator('value', {
            rules: [{ required: true, message: 'Por favor, insira o nome' }],
          })(<Input />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(Edit);