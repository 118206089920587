import { createAsyncActions } from '~/utils/store';

export const LOAD_SETTINGS = createAsyncActions('@@SETTINGS/LOAD');
export const CREATE_SETTINGS = createAsyncActions('@@SETTINGS/CREATE');
export const UPDATE_SETTINGS = createAsyncActions('@@SETTINGS/UPDATE');
export const MERGE_SETTINGS = createAsyncActions('@@SETTINGS/MERGE');
export const DELETE_SETTINGS = createAsyncActions('@@SETTINGS/DELETE');

export const loadSettings = () => ({
  type: LOAD_SETTINGS.INIT,
  payload: {
    request: {
      method: 'GET',
      url: '/settings',
    },
  },
});

export const createSetting = ({ data }) => ({
  type: CREATE_SETTINGS.INIT,
  payload: {
    request: {
      method: 'POST',
      url: '/settings',
      data,
    },
  },
});

export const updateSetting = ({ data, id }) => ({
  type: UPDATE_SETTINGS.INIT,
  payload: {
    request: {
      method: 'PUT',
      url: `/settings/${id}`,
      data,
    },
  },
});

export const deleteSetting = ({ id }) => ({
    type: DELETE_SETTINGS.INIT,
    payload: {
      request: {
        method: 'DELETE',
        url: `/settings/${id}`,
      },
    },
});

export const mergeSetting = ({ data, id }) => ({
  type: MERGE_SETTINGS.INIT,
  payload: {
    request: {
      method: 'PUT',
      url: `/settings/${id}/merge`,
      data,
    },
  },
});