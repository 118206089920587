import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Steps, Icon, Tabs, Popover, Select, Modal } from 'antd';
import moment from 'moment';
import { cadenceOptions, cadenceOptionsIcons } from './helpers';
import CadenceDropdown from './CadenceDropdown';
import HandleAddDate from './handleAddDate';
import HandleInit from './handleInit';

const { Step } = Steps;

const stepStyle = {
    marginBottom: 60,
};


const Cadence = ({
    type,
    data,
    cadenceData,
    setcadenceData,
}) => {
    console.log(data);

    const user = useSelector(state => state.user);
    const usersById = useSelector(state => state.usersById);

    const [activeKey, setActiveKey] = useState(null);

    const firstPendingTab = cadenceData.find(cadence => cadence.status === 'pending');

    const groupedCadence = cadenceData.reduce((acc, cadence) => {
        const date = cadence.date;
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(cadence);
        return acc;
    }, {});


    useEffect(() => {
        if (firstPendingTab) {
            setActiveKey(firstPendingTab.date);
        }
    }, [firstPendingTab]);

    if(cadenceData.length === 0) {
        return (
            <HandleInit 
                type={type}
                setcadenceData={setcadenceData} 
                setActiveKey={setActiveKey} 
            />
        );
    }

    return (
        <div>
            <Tabs 
                activeKey={activeKey} 
                type="editable-card"
                style={{ backgroundColor: '#fff'}}
                onChange={(key) => setActiveKey(key)}
                hideAdd
                onEdit={(targetKey, action) => {
                    if (action === 'remove') {
                        Modal.confirm({
                            title: 'Deseja realmente excluir?',
                            content: 'Essa ação não pode ser desfeita.',
                            okText: 'Sim',
                            okType: 'danger',
                            cancelText: 'Não',
                            onOk() {
                                setcadenceData(cadenceData.filter(c => c.date !== targetKey));
                                setActiveKey(cadenceData.find(cadence => cadence.status === 'pending') ? cadenceData.find(cadence => cadence.status === 'pending').date : cadenceData[cadenceData.length - 1].date);
                            }
                        });
                    }}
                }
                tabBarExtraContent={<HandleAddDate 
                    groupedCadence={groupedCadence} 
                    cadenceData={cadenceData} 
                    setcadenceData={setcadenceData}
                    setActiveKey={setActiveKey} 
                />}
            >
            {Object.keys(groupedCadence).map((date, index) => {
                const allDone = groupedCadence[date].every(cadence => cadence.status === 'done');
                return (
                    <Tabs.TabPane 
                        tab={
                            <span style={{ color: allDone ? '#28a745' : '' }}>
                                <Icon type={allDone ? "check" : "calendar"} style={{ color: allDone ? '#28a745' : '' }} /> 
                                {moment(date, 'DD/MM/YYYY').format('DD/MM')}
                            </span>
                        } 
                        key={date}
                        closable={!allDone}
                        style={{ backgroundColor: '#fff'}}
                    >
                        <Steps 
                            size="small" 
                            current={groupedCadence[date].findIndex(cadence => cadence.status === 'pending')} 
                            direction={groupedCadence[date].length > 4 ? 'vertical' : 'horizontal'}
                            style={stepStyle}>
                            {groupedCadence[date].map((cadence, index) => (
                                <Step 
                                    key={index}
                                    title={
                                        <Popover content={
                                            <div>
                                                <Select defaultValue={`${cadence.type}`} style={{ width: '100%' }} onChange={(value) => {
                                                    setcadenceData(cadenceData.map(c => {
                                                        if (c.uid === cadence.uid) {
                                                            c.type = parseInt(value);
                                                        }
                                                        return c;
                                                    }));
                                                }}>
                                                    {Object.keys(cadenceOptions).map(key => (
                                                        <Select.Option key={key} value={key}>
                                                            <Icon type={cadenceOptionsIcons[key]} /> {cadenceOptions[key]}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        } title="Alterar" trigger="hover">
                                            <Icon type={cadenceOptionsIcons[cadence.type]} /> {cadenceOptions[cadence.type]}
                                        </Popover>
                                    }
                                    description={
                                        <div>
                                            <CadenceDropdown 
                                                cadence={cadence} 
                                                cadenceData={cadenceData} 
                                                setcadenceData={setcadenceData} 
                                                user={user}
                                                usersById={usersById}
                                            />
                                        </div>
                                    } 
                                />
                            ))}
                        </Steps>
                    </Tabs.TabPane>
                );
            })}
            </Tabs>
        </div>
    );
};

export default Cadence;