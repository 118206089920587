import React, { useState } from 'react';
import { Button, Popover, DatePicker, Icon } from 'antd';
import moment from 'moment';

const AddDate = ({
    cadenceData,
    setcadenceData,
    setActiveKey,
    groupedCadence,
}) => {
  const [inputDate, setInputDate] = useState('');
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleAddCadence = () => {
    const selectedDate = inputDate;

    const newCadence = {
      date: selectedDate,
      title: 0,
      type: 0,
      status: 'pending',
    };

    let cadence = [...cadenceData, newCadence];

    // Sort cadence by date asc
    cadence.sort((a, b) => {
      return moment(a.date, 'DD/MM/YYYY').diff(moment(b.date, 'DD/MM/YYYY'));
    });

    setcadenceData(cadence);
    setActiveKey(selectedDate);
    setInputDate('');
    setPopoverVisible(false);
  };

  const handleDateChange = (date, dateString) => {
    setInputDate(moment(dateString, 'DD/MM/YYYY').format('DD/MM/YYYY'));
  };

  const popoverContent = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <DatePicker
        placeholder="Selecione a data"
        format="DD/MM/YYYY"
        onChange={handleDateChange}
      />
      <Button type="primary" size="small" onClick={handleAddCadence}>
        Salvar
      </Button>
    </div>
  );

  return (
    <div>
      <Popover
        content={popoverContent}
        title="Selecione a data"
        trigger="click"
        visible={popoverVisible}
        onVisibleChange={(visible) => setPopoverVisible(visible)}
      >
        <Button type="default" size="small" onClick={() => setPopoverVisible(true)}>
          <Icon type="plus" />
        </Button>
      </Popover>
    </div>
  );
};

export default AddDate;
