import React from 'react';
import styled from 'styled-components';

// Styled components
const TimelineContainer = styled.div`
  position: relative;
  padding: 20px 0;
  border-left: 2px solid #d9d9d9;
`;

const TimelineItem = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding-left: 20px;
`;

const TimelineMarker = styled.div`
  position: absolute;
  left: -6px;
  top: 5px;
  width: 12px;
  height: 12px;
  background-color: #40a9ff;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
`;

const TimelineContent = styled.div`
  background: #f9f9f9;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
`;

const Metadata = styled.div`
  font-size: 12px;
  color: #8c8c8c;
  margin-bottom: 5px;
`;

const Text = styled.div`
  word-wrap: break-word;
`;

const CommentTimeline = ({ comments, usersById }) => {
  return (
    <TimelineContainer>
      {comments.map((comment, index) => (
        <TimelineItem key={index}>
          <TimelineMarker />
          <TimelineContent>
            <Metadata>
              {comment.date} ({(usersById[comment.user] && usersById[comment.user].name) || 'Unknown User'})
            </Metadata>
            <Text>{comment.text}</Text>
          </TimelineContent>
        </TimelineItem>
      ))}
    </TimelineContainer>
  );
};

export default CommentTimeline;
