import uid from 'uid';
import moment from 'moment';

const SELECT = 0;
const SEARCH = 1;
const EMAIL = 2;
const CALL = 3;
const WHATSAPP = 4;

export const cadences = {
    'initial-tel-email':(initialDate = null) => {
        const refDate = initialDate ? moment(initialDate) : moment();
        let cadenceData = [];

        // dia 1
        const date1 = refDate.add(0, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date1,
            type: SEARCH,
            status: 'pending',
            uid: uid(20),
        });
        cadenceData.push({
            date: date1,
            type: EMAIL,
            status: 'pending',
            uid: uid(20),
        });
        cadenceData.push({
            date: date1,
            type: CALL,
            status: 'pending',
            uid: uid(20),
        });

        // dia 3
        const date3 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date3,
            type: CALL,
            status: 'pending',
            uid: uid(20),
        });
        cadenceData.push({
            date: date3,
            type: CALL,
            status: 'pending',
            uid: uid(20),
        });
        
        // dia 5
        const date5 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date5,
            type: EMAIL,
            status: 'pending',
            uid: uid(20),
        });
        cadenceData.push({
            date: date5,
            type: CALL,
            status: 'pending',
            uid: uid(20),
        });

        // dia 7
        const date7 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date7,
            type: CALL,
            status: 'pending',
            uid: uid(20),
        });
        cadenceData.push({
            date: date7,
            type: CALL,
            status: 'pending',
            uid: uid(20),
        });

        // dia 9
        const date9 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date9,
            type: EMAIL,
            status: 'pending',
            uid: uid(20),
        });
        cadenceData.push({
            date:  date9,
            type: CALL,
            status: 'pending',
            uid: uid(20),
        });

        return cadenceData;
    },

    'initial-phone-email':(initialDate = null) => {
        const refDate = initialDate ? moment(initialDate) : moment();
        let cadenceData = [];

        // dia 1
        const date1 = refDate.add(0, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date1,
            type: SEARCH,
            status: 'pending',
            uid: uid(20),
        });
        cadenceData.push({
            date: date1,
            type: EMAIL,
            status: 'pending',
            uid: uid(20),
        });
        cadenceData.push({
            date: date1,
            type: CALL,
            status: 'pending',
            uid: uid(20),
        });

        // dia 3
        const date3 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date3,
            type: CALL,
            status: 'pending',
            uid: uid(20),
        });
        cadenceData.push({
            date: date3,
            type: WHATSAPP,
            status: 'pending',
            uid: uid(20),
        });
        
        // dia 5
        const date5 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date5,
            type: EMAIL,
            status: 'pending',
            uid: uid(20),
        });
        cadenceData.push({
            date: date5,
            type: CALL,
            status: 'pending',
            uid: uid(20),
        });

        // dia 7
        const date7 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date7,
            type: CALL,
            status: 'pending',
            uid: uid(20),
        });
        cadenceData.push({
            date: date7,
            type: WHATSAPP,
            status: 'pending',
            uid: uid(20),
        });

        // dia 9
        const date9 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date9,
            type: EMAIL,
            status: 'pending',
            uid: uid(20),
        });
        cadenceData.push({
            date:  date9,
            type: CALL,
            status: 'pending',
            uid: uid(20),
        });
        cadenceData.push({
            date:  date9,
            type: WHATSAPP,
            status: 'pending',
            uid: uid(20),
        });

        return cadenceData;
    },

    'closing-wpp':(initialDate = null) => {
        const refDate = initialDate ? moment(initialDate) : moment();
        let cadenceData = [];

        // dia 1
        const date1 = refDate.add(0, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date1,
            type: WHATSAPP,
            status: 'pending',
            uid: uid(20),
        });
       

        // dia 3
        const date3 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date3,
            type: WHATSAPP,
            status: 'pending',
            uid: uid(20),
        });
        
        
        // dia 5
        const date5 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date5,
            type: WHATSAPP,
            status: 'pending',
            uid: uid(20),
        });

        // dia 7
        const date7 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date7,
            type: WHATSAPP,
            status: 'pending',
            uid: uid(20),
        });

        return cadenceData;
    },

    'closing-email':(initialDate = null) => {
        const refDate = initialDate ? moment(initialDate) : moment();
        let cadenceData = [];

        // dia 1
        const date1 = refDate.add(0, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date1,
            type: EMAIL,
            status: 'pending',
            uid: uid(20),
        });
       

        // dia 3
        const date3 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date3,
            type: EMAIL,
            status: 'pending',
            uid: uid(20),
        });
        
        
        // dia 5
        const date5 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date5,
            type: EMAIL,
            status: 'pending',
            uid: uid(20),
        });

        // dia 7
        const date7 = refDate.add(2, 'days').format('DD/MM/YYYY');
        cadenceData.push({
            date: date7,
            type: EMAIL,
            status: 'pending',
            uid: uid(20),
        });

        return cadenceData;
    }
};