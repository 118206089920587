import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Select } from "antd";
import { connect } from "react-redux";
import moment from "moment";

const { RangePicker } = DatePicker;

const AdvancedFilter = (props) => {
  const {
    form: { getFieldDecorator, validateFields },
    onSubmit,
    filterByOptions,
  } = props;

  const [filterBy, setFilterBy] = useState(filterByOptions[0].value);

  useEffect(() => {
    validateFields((err, { period }) => {
      if (period) {
        onSubmit({
          from: period[0].format("YYYY-MM-DD"),
          to: period[1].format("YYYY-MM-DD"),
          filterBy,
        }, { from: period[0].format("YYYY-MM-DD"), to: period[1].format("YYYY-MM-DD"), filterBy });
      }
    });
  }, []);

  const handleFilter = (e) => {
    e.preventDefault();

    validateFields((err, { period }) => {
      onSubmit({
        from: period[0].format("YYYY-MM-DD"),
        to: period[1].format("YYYY-MM-DD"),
        filterBy,
      }, { from: period[0].format("YYYY-MM-DD"), to: period[1].format("YYYY-MM-DD"), filterBy });
    });
  }

  const rangeConfig = {
    initialValue: [moment().startOf('year'), moment()],
    rules: [{ type: 'array', required: true, message: 'Por favor, selecione um período!' }],
  };

  return (
    <div style={props.style || {}}>
      <Form
        layout="inline"
        className="ant-advanced-search-form-inline"
        onSubmit={handleFilter}
      >
        {filterByOptions && (
          <Form.Item label="Filtrar por:">
            <Select defaultValue={filterByOptions[0].value} onChange={setFilterBy} style={{ minWidth: '150px' }}>
              {filterByOptions.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Período:">
          {getFieldDecorator('period', rangeConfig)(
            <RangePicker
              format="DD/MM/YYYY"
              ranges={{
                "Hoje": [moment(), moment()],
                "Últimos 7 dias": [moment().subtract(7, 'days'), moment()],
                "Últimos 30 dias": [moment().subtract(30, 'days'), moment()],
                "Este mês": [moment().startOf('month'), moment().endOf('month')],
                "Mês passado": [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                "Últimos 3 meses": [moment().subtract(3, 'months').startOf('month'), moment()],
                "Últimos 6 meses": [moment().subtract(6, 'months').startOf('month'), moment()],
                "Este ano": [moment().startOf('year'), moment().endOf('year')],
                "Ano passado": [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
                "Ano retrasado": [moment().subtract(2, 'year').startOf('year'), moment().subtract(2, 'year').endOf('year')],
              }}
            />
          )}
        </Form.Item>
        <Form.Item>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default connect()(Form.create()(AdvancedFilter));
