import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

const StyledTextArea = styled.textarea`
    width: 100%;
    height: 100px;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-sizing: border-box;
    resize: none;

    &:focus {
        outline: none;
        border-color: #40a9ff;
        box-shadow: 0 0 5px rgba(24, 144, 255, 0.2);
    }

    &::placeholder {
        color: #bfbfbf;
    }
`;

const handleComment = (key, cadence, cadenceData, setCadenceData, user) => {
    if (key === 'include_comment') {
        Modal.confirm({
            title: 'Adicionar Comentário',
            content: (
                <div>
                    <StyledTextArea
                        id="commentInput"
                        placeholder="Adicione seu comentário"
                    />
                </div>
            ),
            okText: 'Confirmar',
            cancelText: 'Cancelar',
            onOk: () => {
                const comment = document.getElementById('commentInput').value;
                const updatedCadenceData = cadenceData.map(c => {
                    if (c.uid === cadence.uid) {
                        if (!c.comments) {
                            c.comments = [];
                        }
                        c.comments.push({
                            date: moment().format('DD/MM/YYYY HH:mm:ss'),
                            text: comment,
                            user: user.id,
                        });
                    }
                    return c;
                });
                setCadenceData(updatedCadenceData);
            },
        });
        return;
    }
};

export default handleComment;