import { Badge, Skeleton } from 'antd';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

export const CalendarContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 2px;
`;

export const CalendarCellContainer = styled.div`
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  font-weight: bold;
`;

export const CalendarCellContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CalendarCellCircle = styled.span`
  background-color: ${({ taskColor }) => taskColor};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export const CalendarCellTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CalendarCellText = styled.span``;

export const Container = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: auto;
`;

export const Column = styled.div`
  flex: 1;
`;

export const ExtraContainer = styled.div``;

export const ExtraBadge = styled(Badge).attrs({
  style: {
    backgroundColor: '#52c41a',
    color: '#fff',
    boxShadow: '0 0 0 1px #52c41a inset',
    marginRight: '3px',
  },
})``;

export const ExtraTotalBadge = styled(Badge).attrs({
  style: {
    backgroundColor: '#fff',
    color: '#999',
    boxShadow: '0 0 0 1px #d9d9d9 inset',
  },
})``;

export const ColumnContent = styled.div`
  padding: 8px;
  width: 100%;
  background-color: #f5f5f5;
  height: 71vh;
  max-height: 71vh;
`;

export const InfiniteScrollExtraContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const InfiniteScrollStyled = styled(InfiniteScroll)`
  ::-webkit-scrollbar {
    height: 12px;
    width: 6px;
    background: #ddd;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #aaa;
  }
`;

export const LoadingSkeletonContainer = styled.div`
  height: 127px;
  margin-bottom: 20px;
`;

export const LoadingSkeleton = styled(Skeleton).attrs({
  active: true,
  title: false,
  paragraph: {
    width: '100%',
    rows: 1,
  },
})`
  .ant-skeleton-content .ant-skeleton-paragraph {
    border: 1px solid #e6e6e6;
  }

  .ant-skeleton-content .ant-skeleton-paragraph li:first-child {
    height: 127px;
  }
`;

export const ConfettiContainer = styled.div`
  position: fixed;
  z-index: 1000;
  inset: 0;
`;