import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Tooltip } from 'antd';
import { WhatsappIcon, phoneMask } from '~/icons/WhatsappIcon';
import moment from 'moment';

import { removeCountryCodeFromPhone } from '~/utils/formatters';
import { copyToClipBoard } from '~/utils/copy-to-clipboard';

const WhatsappLink = styled.a`
  > img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`;

export const table = {
    getColumns: function({columns, showDrawer}) {
        return [
            {
                title: 'Cadastro',
                dataIndex: 'created_at',
                key: 'created_at',
                className: 'text-center',
                sortDirections: ['descend', 'ascend'],
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
                render: (created_at) => moment(created_at).format('DD/MM/YYYY'),
                width: 150,
                ellipsis: true,
            },
            {
                title: 'Lead',
                dataIndex: 'name',
                key: 'name',
                width: 400,
                ellipsis: true,
            },
            {
                title: 'Empresa',
                dataIndex: 'additional_data.company_name',
                key: 'additional_data.company_name',
                width: 400,
                ellipsis: true,
            },
            {
                title: 'Whatsapp',
                dataIndex: 'additional_data.phone',
                key: 'additional_data.phone',
                width: 200,
                ellipsis: true,
                align: 'center',
                render: (phone, customer) => {
                    if (!phone) return '';
                    const p = removeCountryCodeFromPhone(phone).replace(/\D/g, ''); 
                    return (
                    <div style={{textAlign: `center`}}>
                    {p && (
                        <>
                        <Tooltip title="Abrir no Whatsapp">
                        <WhatsappLink
                            href={`https://api.whatsapp.com/send?phone=55${p}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <WhatsappIcon />
                        </WhatsappLink> 
                        </Tooltip>{' '}
                        <Tooltip title={'Clique para copiar'}>
                        <span onClick={() => copyToClipBoard(p)} style={{cursor: 'pointer'}}>
                            {phoneMask(p)}
                        </span>
                        </Tooltip>
                        </>
                    )}
                    </div>
                )},
            },
            {
                title: 'Etapa',
                dataIndex: 'column_id',
                key: 'column_id',
                width: 200,
                ellipsis: true,
                render: (column_id) => {
                    const column = columns.find(c => c.id === column_id);
                    return column ? (
                    <span style={{ color: column.style.color }}>
                        {column.name}
                    </span>
                    ) : '';
                },
                filters: columns.map(column => ({ text: column.name, value: column.id })),
                onFilter: (value, record) => record.column_id === value,
            },
            {
                title: '',
                width: 80,
                key: 'id',
                className: 'text-center',
                render: (id, data) => (
                    <center>
                    <Button type="default" onClick={(() => showDrawer(data))}>
                        <Icon type="eye" />
                    </Button>
                    </center>
                ),
                fixed: 'right',
            }
        ];
    }
}