import { createAsyncActions } from '~/utils/store';

export const SEARCH = createAsyncActions('@@LEADS_TABLE/SEARCH');

export const loadLeads = ({ ...params }) => ({
    type: SEARCH.INIT,
    payload: {
        request: {
            method: "GET",
            url: `/leads/period?from=${params.from}&to=${params.to}&filterBy=${params.filterBy}`,
            ...params,
            showLoading: false
        }
    }
});