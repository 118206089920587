import React, { useState } from "react";
import "moment/locale/pt-br";

import {
  Container,
  RegistrationButton,
} from "./styles";
import { ModuleHeader } from '~/components/ModuleHeader';
import { Icon, Radio, Dropdown, Menu } from "antd";
import Import from "./Import";

export const Header = ({
  showDrawer,
  isSeventhOnboardingStepActive,
  displayMode,
  setDisplayMode,
}) => {
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);

  return (
    <Container>
      <Import 
        visible={isImportModalVisible}
        onCancel={() => setIsImportModalVisible(false)}
      />
      <ModuleHeader 
        breadcrumbs={['CRM', 'Prospeção']}
        title={<span>Prospecção Ativa</span>}
        actions={
          <>
          <Radio.Group
            value={displayMode}
            onChange={(event) => setDisplayMode(event.target.value)}
          >
            <Radio.Button value="funnel">
              <Icon type="funnel-plot" />
            </Radio.Button>
            <Radio.Button value="table">
              <Icon type="table" />
            </Radio.Button>
          </Radio.Group>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={() => showDrawer()}>
                  <Icon type="edit" /> Cadastro manual
                </Menu.Item>
                <Menu.Item key="2" onClick={() => setIsImportModalVisible(true)}>
                  <Icon type="upload" /> Importar em lote
                </Menu.Item>
              </Menu>
            }
          >
            <RegistrationButton
              type="primary"
              style={{ zIndex: isSeventhOnboardingStepActive ? 999 : "auto" }}
            >
              <Icon type="folder-add" /> Novo
            </RegistrationButton>
          </Dropdown>
          </>
        }
      />
    </Container>
  );
};