import React from 'react';
import { Collapse } from 'antd';
import Cadence from './Cadence';
import styled from 'styled-components';

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  .ant-tabs {
    background-color: rgb(255, 255, 255) !important;
    padding: 20px !important;
`;

const customPanelStyle = {
    background: '#f7f7f7',
    borderRadius: 4,
    marginBottom: 24,
    border: 0,
    overflow: 'hidden',
  };

const Cadences = ({ data, cadenceData, setcadenceData }) => {
    return (
        <StyledCollapse defaultActiveKey={['1']} bordered={false}>
            <Panel header="Cadência inicial" key="1" style={customPanelStyle}>
                <Cadence 
                    data={data} 
                    cadenceData={cadenceData && cadenceData['initial'] || []}
                    setcadenceData={(data) => setcadenceData({ ...cadenceData, 'initial': [...data] })}
                    type="initial"
                />
            </Panel>
            <Panel header="Cadência de fechamento" key="2" style={customPanelStyle}>
                <Cadence 
                    data={data} 
                    cadenceData={cadenceData && cadenceData['closing'] || []}
                    setcadenceData={(data) => setcadenceData({ ...cadenceData, 'closing': [...data] })}
                    type="closing"
                />
            </Panel>
        </StyledCollapse>
    );
};

export default Cadences;