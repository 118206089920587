
import React from 'react';
import { Button, Icon, Dropdown } from 'antd';
import { Menu } from 'antd';
import { cadences } from './initialCadence';

const HandleInit = ({ type, setcadenceData, setActiveKey }) => {

    const handleMenuClick = (e) => {
        const cadence = cadences[e.key]();
        console.log(cadence);
        setcadenceData(cadence);
        setActiveKey(cadence.date);
    }

    const menuInitial = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="initial-tel-email">Telefone e email</Menu.Item>
            <Menu.Item key="initial-phone-email">Celular e WhatsApp</Menu.Item>
        </Menu>
    );
    
    const closingMenu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="closing-wpp">Whatsapp</Menu.Item>
            <Menu.Item key="closing-email">Email</Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={
            type === 'initial' ? menuInitial : closingMenu
        } trigger={['click']}>
            <Button type="default" size="small">
                <Icon type="plus" /> Iniciar
            </Button>
        </Dropdown>
    );
};

export default HandleInit;