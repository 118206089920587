import moment from "moment";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import get from "lodash/get";

import { convertToBRL, limitCharacterLength } from "~/utils/formatters";
import { LeadCalendarIcon } from "../LeadCalendarIcon";
import { Icon } from "antd";
import {
  CalendarIcon,
  Content,
  Date,
  DateContainer,
  LeadInfo,
  LeadName,
  LeftTopContent,
  RightTopContent,
  MainContent,
  Container,
  Value,
} from "./styles";

export function LeadCard({
  index,
  item,
  showDrawer,
}) {
  return (
    <Draggable key={index} draggableId={item.id} index={index}>
      {(providedProps, snapshotProps) => {
        const { innerRef, draggableProps, dragHandleProps } = providedProps;
        const { isDragging, isDraggingOver } = snapshotProps;

        return (
          <Container
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
            isDragging={isDragging}
            isDraggingOver={isDraggingOver}
            onClick={() => showDrawer(item)}
          >
            <LeftTopContent>
              <DateContainer>
                <CalendarIcon />
                <Date>{moment(item.created_at).fromNow()}</Date>
              </DateContainer>
            </LeftTopContent>
            <RightTopContent>
            {!!get(item, "additional_data.honorary") && (
                <Value>{convertToBRL(item.additional_data.honorary)}</Value>
              )}
            </RightTopContent>
            <Content>
              <MainContent>
                <LeadCalendarIcon item={item} />
                <LeadInfo>
                  <LeadName><Icon type="user"/> {limitCharacterLength(item.name, 33)}</LeadName>
                </LeadInfo>
              </MainContent>
            </Content>
          </Container>
        );
      }}
    </Draggable>
  );
}
