import { get } from 'lodash';
import moment from 'moment';

export const getFistPendingTask = (cadence) => {
    const initial = get(cadence, 'initial');
    let firstPending = initial.find((task) => task.status === 'pending');
    
    if (!firstPending) {
        const closing = get(cadence, 'closing');
        firstPending = closing.find((task) => task.status === 'pending');
    }

    firstPending.timestamp = moment(firstPending.timestamp);
    
    return firstPending;
};