export const PERMISSIONS = {
  users: {
    view: "Usuários",
  },
  customers: {
    view: "Clientes",
    create: "customers.create",
    edit: "customers.edit",
    delete: "customers.delete",
  },
  checklist: {
    view: "checklist",
    columns: {
      legalization: "checklist.columns.legalization",
      administrative: "checklist.columns.administrative",
      accounting: "checklist.columns.accounting",
      fiscal: "checklist.columns.fiscal",
      personal: "checklist.columns.personal",
      cs: "checklist.columns.cs",
    },
  },
  calendarTasks: {
    view: "Tarefas",
    create: "Cadastro de Tarefas",
  },
  documents: {
    view: "documents",
  },
  corporate: {
    view: "Abertura e Encerramento",
    create: "societario.create",
    createBillsToPay: "Abertura e Encerramento (Criar contas a pagar)",
    financial: {
      view: "societario.financial.view",
    },
  },
  certificates: {
    view: "Certificados",
  },
  procurations: {
    view: "procurations.view",
  },
  conventions: {
    view: "Convenções",
  },
  financial: {
    monthlyPayments: {
      view: "financeiro.mensalidades",
      edit: "financeiro.mensalidades.edit",
      delete: "financeiro.mensalidades.delete",
    },
  },
  extraServices: {
    view: "se.view",
    edit: "se.edit",
    delete: "se.delete"
  },
  refunds: {
    view: "refunds",
    edit: "refunds.edit",
    delete: "refunds.delete",
  },
  leads: {
    view: "leads",
  },
  leadsRT: {
    view: "leadsRT",
  },
  prospecting: {
    view: "prospecting",
  },
  irpf: {
    view: "IRPF",
    summary: {
      view: "IRPF.summary",
    },
    malhaFina: {
      view: "IRPF.malha_fina",
    },
  },
  links: {
    view: "links.view",
  },
  documentsDeadline: {
    view: "documents_deadline.view",
  },
  honoraries: {
    view: "honoraries.view",
  },
  sentEmails: {
    view: "sent_emails.view",
  },
  feedbacks: {
    view: "feedbacks.view",
  },
  boards: {
    view: "boards.view",
  },
  solicitations: {
    view: "solicitations.view",
  },
  referralProgram: {
    view: "referral_program.view",
  },
  plugins: {
    view: "plugins.view",
  },
  knowledgeBase: {
    view: "knowledge_base.view",
  },
  reports:{
    defaulters: 'reports.defaulters.view',
  },
  graphs: {
    monthlyPayments: {
      view: "monthly_payments_graph",
    },
    customersCount: {
      view: "customers_count_graph",
    },
    dashTasks: {
      view: "dash_tasks_summary",
    },
    extraPayments: {
      view: "extra_payments_graph",
    },
    calendarTasks: {
      view: "calendar_tasks_graph",
    },
    certificates: {
      view: "certificates_summary",
    },
    leads: {
      view: "leads_graph",
    },
    customersByType: {
      view: "customers_by_type_graph",
    },
  },
};
