import React from 'react';
import { Route, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Row, Col } from 'antd';

import { PERMISSIONS } from "~/constants/permissions.constants";

import Layout from '../components/UI/Layout/Layout';
import SettingsBasicForm from '../components/Account/SettingsBasicForm';
import SettingsCompanyForm from '../components/Account/SettingsCompanyForm';
import ChangePasswordForm from '../components/Account/ChangePasswordForm';
import ModuleMonthlyForm from '~/components/Account/ModuleMonthlyForm';
import { SporadicDocumentTypes } from '~/components/Account/SporadicDocumentTypes';
import { ChecklistTasks } from '~/components/Account/ChecklistTasks';
import { WhatsappByDepartment } from '~/components/Account/WhatsappByDepartment';
import SystemVariables from '~/components/Account/SystemVariables';

import Wpp from './Account/Wpp';


const _title = {
    fontSize: `20px`,
    marginBottom: `15px`
};

const ScreensAccount = ({ user }) => {
    const location = useLocation();

    const isPremiumPlan = user.tenant.plan === 'premium';

    return (
        <Layout>
            <Row style={{
                backgroundColor: `#fff`,
                padding: `15px 15px 15px 0px`,
                marginTop: `20px`
            }}>
                <Col span={6}>
                    <Menu
                        defaultSelectedKeys={[location.pathname]}
                        mode={'inline'}
                        theme={'light'}>
                        <Menu.Item key="/conta">
                            <Link to="/conta">Configurações Básicas</Link>
                        </Menu.Item>
                        {!!user.id && user.permissions_group_id === 1 && (
                            <Menu.Item key="/conta/empresa">
                                <Link to="/conta/empresa">Configurações da Empresa</Link>
                            </Menu.Item>
                        )}
                        {!!user.id && user.permissions_group_id === 1 && (
                            <Menu.Item key="/conta/mensalidades">
                                <Link to="/conta/mensalidades">Módulo mensalidades</Link>
                            </Menu.Item>
                        )}
                        {!!user.id && user.permissions_group_id === 1 && (
                            <Menu.Item key="/conta/tipos-de-documentos">
                                <Link to="/conta/tipos-de-documentos">Tipos de documentos esporádicos</Link>
                            </Menu.Item>
                        )}
                        {!!user.id && user.permissions_group_id === 1 && (
                            <Menu.Item key="/conta/tarefas-do-checklist">
                                <Link to="/conta/tarefas-do-checklist">Tarefas do checklist</Link>
                            </Menu.Item>
                        )}
                        {!!user.id && user.permissions_group_id === 1 && (
                            <Menu.Item key="/conta/variaveis">
                                <Link to="/conta/variaveis">Variáveis do sistema</Link>
                            </Menu.Item>
                        )}
                        {!!user.id && user.permissions_group_id === 1 && (
                            <Menu.Item key="/conta/whatsapp-por-departamento">
                                <Link to="/conta/whatsapp-por-departamento">Whatsapp por departamento</Link>
                            </Menu.Item>
                        )}
                        {isPremiumPlan && user.permissions.includes(PERMISSIONS.leads.view) && (
                        <Menu.Item key="/conta/wpp">
                            <Link to="/conta/wpp">Extensão do Whatsapp</Link>
                        </Menu.Item>
                        )}
                        <Menu.Item key="/conta/senha">
                            <Link to="/conta/senha">Alterar senha</Link>
                        </Menu.Item>
                    </Menu>
                </Col>
                <Col span={18} style={{ paddingLeft: `25px` }}>
                    <Route path="/conta" exact render={() => (
                        <Row>
                            <Col offset={4}>
                                <h1 style={{ ..._title }}>Configurações Básicas</h1>
                            </Col>
                            <Col>
                                <SettingsBasicForm />
                            </Col>
                        </Row>
                    )} />
                    {!!user.id && user.permissions_group_id === 1 && (
                        <Route path="/conta/empresa" exact render={() => (
                            <Row>
                                <Col offset={4}>
                                    <h1 style={{ ..._title }}>Configurações da Empresa</h1>
                                </Col>
                                <Col>
                                    <SettingsCompanyForm />
                                </Col>
                            </Row>
                        )} />
                    )}
                    <Route path="/conta/senha" exact render={() => (
                        <Row>
                            <Col offset={4}>
                                <h1 style={{ ..._title }}>Alterar senha</h1>
                            </Col>
                            <Col>
                                <ChangePasswordForm />
                            </Col>
                        </Row>
                    )} />
                    {!!user.id && user.permissions_group_id === 1 && (
                        <Route path="/conta/mensalidades" exact render={() => (
                            <Row>
                                <Col offset={4}>
                                    <h1 style={{ ..._title }}>Módulo mensalidades</h1>
                                </Col>
                                <Col>
                                    <ModuleMonthlyForm />
                                </Col>
                            </Row>
                        )} />
                    )}
                    {!!user.id && user.permissions_group_id === 1 && (
                        <Route
                            path="/conta/tipos-de-documentos"
                            exact
                            render={() => (
                                <Row>
                                    <Col offset={4}>
                                        <h1 style={{ ..._title }}>Tipos de documentos esporádicos</h1>
                                    </Col>
                                    <Col>
                                        <SporadicDocumentTypes />
                                    </Col>
                                </Row>
                            )}
                        />
                    )}
                    {!!user.id && user.permissions_group_id === 1 && (
                        <Route
                            path="/conta/tarefas-do-checklist"
                            exact
                            render={() => (
                                <Row>
                                    <Col offset={4}>
                                        <h1 style={{ ..._title }}>Tarefas do checklist</h1>
                                    </Col>
                                    <Col>
                                        <ChecklistTasks />
                                    </Col>
                                </Row>
                            )}
                        />
                    )}
                    {!!user.id && user.permissions_group_id === 1 && (
                        <Route
                            path="/conta/variaveis"
                            exact
                            render={() => (
                                <Row>
                                    <Col offset={4}>
                                        <h1 style={{ ..._title }}>Variáveis do sistema</h1>
                                    </Col>
                                    <Col>
                                        <SystemVariables />
                                    </Col>
                                </Row>
                            )}
                        />
                    )}
                    {!!user.id && user.permissions_group_id === 1 && (
                        <Route
                            path="/conta/whatsapp-por-departamento"
                            exact
                            render={() => (
                                <Row>
                                    <Col offset={4}>
                                        <h1 style={{ ..._title }}>Whatsapp por departamento</h1>
                                    </Col>
                                    <Col>
                                        <WhatsappByDepartment />
                                    </Col>
                                </Row>
                            )}
                        />
                    )}
                    {isPremiumPlan && user.permissions.includes(PERMISSIONS.leads.view) && (
                        <Route path="/conta/wpp" exact render={() => (
                            <Row>
                                <Col offset={2}>
                                    <h1 style={{ ..._title }}>Como utilizar a Extensão do Whatsapp?</h1>
                                </Col>
                                <Col offset={2} span={20}>
                                    <Wpp user={user}/>
                                </Col>
                            </Row>
                        )} />
                    )}
                </Col>
            </Row>
        </Layout>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(ScreensAccount);