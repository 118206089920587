import React, { Component } from 'react';
import { Form, Col, Row, Input, InputNumber, Select, Divider, Switch, Radio, Progress, Button } from 'antd';
import InputCurrency, { currency2Float } from '~components/UI/InputCurrency';
import { InstallmentsTable } from '../../Tables/InstallmentsTable';
import { PERMISSIONS } from '~/constants/permissions.constants';
import { OrgNames } from '~/components/SelectWithAdd';

const { Option } = Select;

class Opening extends Component {
  state = {
    default: {
      billing: 0.00,
      nfs_input: 0,
      nfs_output: 0,
      accounting_postings: 0,
      conciliation: false,
      n_staffs: 0,
      type_staff: null,
      first_honorary: 0.00,
      honorary: 0.00,
      first_honorary_c_payment: false,
      honorary_c_payments: false,
      paid_off: false,
      pendency: 0,
      org_name: null,
      org_number: null,
      org_password: null
    }
  };

  createBills = (val) => {
    if (val) {
      this.props.openBillForm({
        task_name: 'Honorário de abertura',
        total: this.props.form.getFieldValue('first_honorary')
      });
    } else {
      this.props.setBillsData(null);
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { user, dashboard } = this.props;
    const data = {
      ...this.state.default,
      ...this.props.data
    }

    const payment = this.props.billsPayment;
    const billsData = this.props.billsData;

    return (
      <div>
        <Row gutter={16} className="mb-20">
          <Divider orientation="left">Conselho</Divider>
            <Col span={8}>
                <Form.Item label="Nome do Conselho">
                    {getFieldDecorator('org_name', {
                        initialValue: parseInt(data.org_name) || data.org_name,
                    })(
                        <OrgNames placeholder="Nome do conselho"/>
                    )}
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item label="Número do Conselho">
                    {getFieldDecorator('org_number', {
                        initialValue: data.org_number,
                    })(
                        <Input placeholder="Número do conselho"/>
                    )}
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item label="Senha">
                    {getFieldDecorator('org_password', {
                        initialValue: data.org_password,
                    })(
                        <Input placeholder="Senha do conselho"/>
                    )}
                </Form.Item>
            </Col>
          <Divider orientation="left">Departamento Fiscal</Divider>
          <Col span={8}>
            <Form.Item label={<span>Faturamento <small>(mensal)</small></span>}>
              {getFieldDecorator('billing', {
                initialValue: data.billing,
                normalize: (value) => currency2Float(value)
              })(
                <InputCurrency />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={<span>NFs Entrada <small>(mensal)</small></span>}>
              {getFieldDecorator('nfs_input', {
                initialValue: data.nfs_input,
              })(
                <InputNumber style={{ width: `100%` }} />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={<span>NFs Saída <small>(mensal)</small></span>}>
              {getFieldDecorator('nfs_output', {
                initialValue: data.nfs_output,
              })(
                <InputNumber style={{ width: `100%` }} />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className="mb-20">
          <Divider orientation="left">Departamento Contábil</Divider>
          <Col span={8}>
            <Form.Item label={<span>Lanç. Contábeis <small>(mensal)</small></span>}>
              {getFieldDecorator('accounting_postings', {
                initialValue: data.accounting_postings,
              })(
                <InputNumber style={{ width: `100%` }} />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Conciliação">
              {getFieldDecorator('conciliation', {
                valuePropName: 'checked',
                initialValue: data.conciliation
              })(
                <Switch />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className="mb-20">
          <Divider orientation="left">Departamento Pessoal</Divider>
          <Col span={12}>
            <Form.Item label="Funcionários">
              {getFieldDecorator('n_staffs', {
                initialValue: data.n_staffs,
              })(
                <InputNumber style={{ width: `100%` }} />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tipo">
              {getFieldDecorator('type_staff', data.id !== null ? { initialValue: data.type_staff } : {})(
                <Select placeholder="Selecione um tipo de funcionário">
                  <Option value="Honorista">Honorista</Option>
                  <Option value="Mensalista">Mensalista</Option>
                  <Option value="Comissionista">Comissionista</Option>
                  <Option value="Comissionista Puro">Comissionista Puro</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        {/* {(dashboard || data.id) && user.permissions.includes(PERMISSIONS.corporate.financial.view) && (
          <div>
            <Divider orientation="left">Departamento Financeiro</Divider>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Honorário de Abertura">
                  {getFieldDecorator('first_honorary', {
                    initialValue: data.first_honorary,
                    normalize: (value) => currency2Float(value)
                  })(
                    <InputCurrency />
                  )}
                </Form.Item>
              </Col>
              {!payment && (
                (getFieldValue('first_honorary') > 0) && (
                  <Col span={8}>
                    <Form.Item label="Criar contas a pagar">
                      <Switch
                        checked={!!this.props.billsData}
                        disabled={(!user.permissions.includes(PERMISSIONS.corporate.createBillsToPay))}
                        onChange={this.createBills}
                      />
                    </Form.Item>
                  </Col>
                )
              )}
              {payment && (
                <Col span={8}>
                  <Form.Item label="Status do Pagamento">
                    <Progress percent={this.props.getPaymentPercentage(payment)} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            {(payment || billsData) && (
              <Row gutter={16}>
                <Col>
                <Form.Item label={<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <span>Parcelas</span>
                    <Button
                      disabled={(!user.permissions.includes(PERMISSIONS.extraServices.edit))}
                      onClick={this.createBills}
                      type="primary"
                    >
                      Editar
                    </Button>
                  </div>}>
                  <InstallmentsTable
                    payment={payment}
                    billsData={billsData}
                  />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </div>
        )} */}
      </div>
    )
  }
}

export default Opening;