import { PERMISSIONS } from "~/constants/permissions.constants";
import { parents } from "./permissions-parents";

export const permissions = [
  {
    key: PERMISSIONS.graphs.monthlyPayments.view,
    name: "Mensalidade x Recebido",
    parent: parents.DASHBOARD,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.graphs.customersByType.view,
    name: "Clientes por tipo",
    parent: parents.DASHBOARD,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.graphs.customersCount.view,
    name: "Quantidade de Clientes",
    parent: parents.DASHBOARD,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.solicitations.view,
    name: "Solicitações",
    parent: parents.SOLICITATIONS,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.graphs.dashTasks.view,
    name: "Serviços extras / Tarefas",
    parent: parents.DASHBOARD,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.graphs.extraPayments.view,
    name: "Serviços extras / Financeiro",
    parent: parents.DASHBOARD,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.graphs.calendarTasks.view,
    name: "Tarefas do mês",
    parent: parents.DASHBOARD,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.graphs.certificates.view,
    name: "Gráfico Certificados",
    parent: parents.DASHBOARD,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.graphs.leads.view,
    name: "Leads",
    parent: parents.DASHBOARD,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.customers.view,
    name: "Clientes",
    parent: parents.CLIENTES,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.customers.create,
    name: "Clientes (Criar)",
    parent: parents.CLIENTES,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.customers.edit,
    name: "Clientes (Editar)",
    parent: parents.CLIENTES,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.customers.delete,
    name: "Clientes (Remover)",
    parent: parents.CLIENTES,
    plans: ["starter", "premium"],
  },
  { 
    key: PERMISSIONS.users.view, 
    name: "Usuários", 
    parent: parents.USUARIOS,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.checklist.view,
    name: "Visualizar",
    parent: parents.TAREFAS,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.checklist.columns.legalization,
    name: "Legalização",
    parent: parents.TAREFAS,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.checklist.columns.administrative,
    name: "Administrativo",
    parent: parents.TAREFAS,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.checklist.columns.accounting,
    name: "Contábil",
    parent: parents.TAREFAS,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.checklist.columns.fiscal,
    name: "Fiscal",
    parent: parents.TAREFAS,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.checklist.columns.personal,
    name: "Pessoal",
    parent: parents.TAREFAS,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.calendarTasks.create,
    name: "Cadastro de Tarefas",
    parent: parents.TAREFAS,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.calendarTasks.view,
    name: "Tarefas",
    parent: parents.TAREFAS,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.documents.view,
    name: "Documentos enviados",
    parent: parents.ITENS_ENVIADOS,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.sentEmails.view,
    name: "E-mails enviados",
    parent: parents.ITENS_ENVIADOS,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.corporate.view,
    name: "Abertura e Encerramento",
    parent: parents.SOCIETARIO,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.corporate.create,
    name: "Abertura e Encerramento (Criação)",
    parent: parents.SOCIETARIO,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.corporate.createBillsToPay,
    name: "Abertura e Encerramento (Criar contas a pagar)",
    parent: parents.SOCIETARIO,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.corporate.financial.view,
    name: "Visualizar departamento financeiro",
    parent: parents.SOCIETARIO,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.certificates.view,
    name: "Certificados",
    parent: parents.SOCIETARIO,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.procurations.view,
    name: "Lembretes",
    parent: parents.SOCIETARIO,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.conventions.view,
    name: "Convenções",
    parent: parents.TAREFAS,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.financial.monthlyPayments.view,
    name: "Mensalidades",
    parent: parents.FINANCEIRO,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.financial.monthlyPayments.edit,
    name: "Mensalidades (Editar)",
    parent: parents.FINANCEIRO,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.financial.monthlyPayments.delete,
    name: "Mensalidades (Remover)",
    parent: parents.FINANCEIRO,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.extraServices.view,
    name: "Serviços Extras (Visualizar)",
    parent: parents.FINANCEIRO,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.extraServices.edit,
    name: "Serviços Extras (Editar)",
    parent: parents.FINANCEIRO,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.extraServices.delete,
    name: "Serviços Extras (Remover)",
    parent: parents.FINANCEIRO,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.refunds.view,
    name: "Comissões",
    parent: parents.FINANCEIRO,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.refunds.edit,
    name: "Comissões (Editar)",
    parent: parents.FINANCEIRO,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.refunds.delete,
    name: "Comissões (Remover)",
    parent: parents.FINANCEIRO,
    plans: ["premium"],
  },
  { 
    key: PERMISSIONS.leads.view, 
    name: "Leads", 
    parent: parents.LEADS,
    plans: ["premium"],
  },
  { 
    key: PERMISSIONS.leadsRT.view, 
    name: "leadsRT", 
    parent: parents.LEADSRT ,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.prospecting.view,
    name: "prospecting", 
    parent: parents.PROSPECTIONS ,
    plans: ["beta"],
  },
  { 
    key: PERMISSIONS.irpf.view, 
    name: "IRPF", 
    parent: parents.TAREFAS,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.irpf.summary.view,
    name: "IRPF Sumário",
    parent: parents.TAREFAS,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.irpf.malhaFina.view,
    name: "Malha Fiscal",
    parent: parents.SOCIETARIO,
    plans: ["premium"],
  },
  { 
    key: PERMISSIONS.links.view, 
    name: "Links", 
    parent: parents.LINKS,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.documentsDeadline.view,
    name: "Prazos",
    parent: parents.RELATORIOS,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.honoraries.view,
    name: "Controle de Honorários",
    parent: parents.RELATORIOS,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.feedbacks.view,
    name: "KPIs",
    parent: parents.RELATORIOS,
    plans: ["premium"],
  },
  {
    key: PERMISSIONS.plugins.view,
    name: "Plugins",
    parent: parents.PLUGINS,
    plans: ["starter", "premium"],
  },
  {
    key: PERMISSIONS.knowledgeBase.view,
    name: "Base de Conhecimento",
    parent: parents.BASE_DE_CONHECIMENTO,
    plans: ["starter", "premium"],
  }
];
