import styled from 'styled-components';
import { Button } from 'antd';

export const StatusTxt = styled(Button)`
    margin-top: 10px;
    flex: 1;
    justify-content: center;
    cursor: default;
    max-width: 150px;

    span {
        font-size: 12px;
        color: ${props => props.status === 'done' ? '#28a745' : ''};
    }

    &:hover {
        cursor: default;
    }
`;